// theme.ts
// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from '@chakra-ui/react';

/*
 Ad Auris Branding Guide: https://drive.google.com/file/d/1ADPoIf0q43E8NxszkzYOwcx7PRKG8ZYX/view 
 Good Article on setting up a design system on Chakra UI: https://medium.com/meliopayments/why-we-chose-chakra-ui-for-our-design-system-part-2-36865e5ec2be
*/

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false
};

// Generated Branding Color Palate via this tool: https://palx.jxnblk.com with this base hex #89BE00
// This tool works too https://smart-swatch.netlify.app/#89BE00

// default colors are displayed here https://chakra-ui.com/docs/styled-system/theme#colors
const colors = {
    brand: {
        50: '#f4fcf2',
        100: '#dcf4d5',
        200: '#c2ecb6',
        300: '#a4e392',
        400: '#82B401',
        500: '#5b7f3b',
        550: '#50812F',
        600: '#5b7f3b', // same as 500 since paginator library uses brand.600 as colour
        650: '#2bb900',
        700: '#259e00',
        800: '#1d7d00',
        900: '#114a00',
        1000: '#74a24a' // monochromatic partner of brand.500 - generated by https://www.canva.com/colors/color-wheel/
    },
    red: {
        350: '#D9746F'
    },
    gray: {
        750: '#363636'
    },
    warning: '#FADF72'
};

const fonts = {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif'
};

const shadows = {
    outlineWhite: '0 0 0 3px rgba(255, 255, 225, 0.6)',
    mdWhite: '0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06)'
};

const breakpoints = {
    '425px': '26.6em' // breakpoint used by react-h5-audio-player at 425px
};

const defaultProps = {
    colorScheme: 'brand'
};

const styles = {
    global: () => ({
        body: {
            // background: '#cbccc8'
            background: 'white'
        }
    })
};

// 3. extend the theme
const globalTheme = extendTheme({
    config,
    colors,
    fonts,
    shadows,
    breakpoints,
    defaultProps,
    styles
});

export default globalTheme;
