import { useState, createContext, useRef, useContext, FC, ReactNode } from 'react';
import { GlobalNarrationContextInterface, NarrationInterface } from '../types/types';
import { captureException } from '@sentry/nextjs';
import { formatSentryError, formatSentryMessage } from '../helpers/sentryHelpers';

export const GlobalNarrationContext = createContext<GlobalNarrationContextInterface>({
    playing: false,
    setPlaying: () => {},
    onEnded: () => {},
    setOnEnded: () => {},
    selectedNarration: null,
    setSelectedNarration: () => {},
    playerRef: null,
    playPause: () => {},
    distinctId: '',
    setDistinctId: () => {}
});

// hook that we can use anywhere in the app
export const useGlobalNarrationContext = () => useContext(GlobalNarrationContext);

const GlobalNarrationProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [playing, setPlaying] = useState<boolean>(false);
    const [onEnded, setOnEnded] = useState<Function>(() => {
        return () => {
            console.log('ended');
        };
    });
    const [distinctId, setDistinctId] = useState('');
    const [selectedNarration, setSelectedNarration] = useState<NarrationInterface | null>(null);
    const playerRef: any = useRef();

    const playPause = () => {
        try {
            if (playerRef.current) {
                if (playerRef.current.audio.current.paused) {
                    playerRef.current.audio.current.play();
                } else {
                    playerRef.current.audio.current.pause();
                }
            } else {
                const message = formatSentryMessage('Missing "current" property on audio player React ref', 'playPause in globalNarration Context');
                captureException(new Error('Missing "current" property on audio player React ref - via playPause in globalNarration Context'));
            }
        } catch (err: unknown) {
            const { message, cause } = formatSentryError('Error playing or pausing a narration', 'playPause in globalNarration Context', err);
            captureException(new Error(message, cause));
        }
    };

    return (
        <GlobalNarrationContext.Provider
            value={{
                playing,
                setPlaying,
                playerRef,
                playPause,
                selectedNarration,
                setSelectedNarration,
                onEnded,
                setOnEnded,
                distinctId,
                setDistinctId
            }}
        >
            {children}
        </GlobalNarrationContext.Provider>
    );
};

export default GlobalNarrationProvider;
