export const formatSentryError = (context: string, component: string, error: unknown) => {
    const message = formatSentryMessage(context, component);
    const cause = { cause: error as Error };

    return { message, cause };
};

// use when you want to log an error in sentry in a location where no exception was caught
export const formatSentryMessage = (context: string, component: string) => {
    return `${context} - via ${component}`;
};