import type { AppProps } from 'next/app';
import globalTheme from '../themes/global.theme';
import '../styles/globals.css';
import 'react-h5-audio-player/lib/styles.css';
import { ChakraProvider } from '@chakra-ui/react';
import NextNProgress from 'nextjs-progressbar';
import GlobalNarrationProvider from '../context/globalNarration';
import { useEffect } from 'react';

export default function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        // ! Uncomment if we want to autocapture events but currently we only manually capture events to reduce costs
        // const posthog = initPostHog();
        // if (typeof window !== 'undefined') {
        //     posthog.capture('$pageview');
        // }
    }, []);

    return (
        <>
            <NextNProgress color="#82b401" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={true} />
            <ChakraProvider theme={globalTheme}>
                <GlobalNarrationProvider>
                    <Component {...pageProps} />
                </GlobalNarrationProvider>
            </ChakraProvider>
        </>
    );
}
